import React from "react"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import Pontuation from "../Pontuation/Pontuation"

import styles from "./styles.module.css"

const Card = ({ icon, title, description }) => (
  <div className={styles.card}>
    <Img fixed={icon.childImageSharp.fixed} className={styles.icon} />
    <h1 className={styles.title}>
      {title}
      <Pontuation>.</Pontuation>
    </h1>
    <ReactMarkdown className={styles.description} source={description} />
  </div>
)

export default Card
