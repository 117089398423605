import React from "react"
import { Link } from "gatsby"

import SimpleLayout from "../Layout/SimpleLayout"

import Button from "../Button/Button"
import Card from "../Card/Card"
import Pontuation from "../Pontuation/Pontuation"
import TechSection from "../TechSection/TechSection"
import SEO from "../seo"

import styles from "./styles/home.module.css"
import ReactMarkdown from "react-markdown"

const Home = ({ page, headerImg, valuesImg, backgroudImg, ...props }) => {
  const {
    title,
    subtitle,
    businessFeaturesSection,
    valuesSection,
    technologySection,
    portfolioSection,
    contactSection,
    customersSection,
  } = page

  return (
    <SimpleLayout path={props.path}>
      <SEO
        title="LeapLane"
        description="Software development. Web, Android and iOS applications development. We are a software house with a team of young and motivated developers."
      />
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.left}>
            <h1 className={styles.sectionTitle}>
              {title}
              <Pontuation>.</Pontuation>
            </h1>
            <h2 className={styles.sectionDescription}>{subtitle}</h2>
            <div className={styles.row}>
              <Button type="transparent" onPress={() => {}}>
                Learn more
              </Button>
              <Link to="/portfolio">
                <Button
                  type="primary"
                  onPress={() => {}}
                  style={{ marginLeft: 20 }}
                >
                  Our projects
                </Button>
              </Link>
            </div>
          </div>
          <div className={styles.right}>
            <img
              src={headerImg.fixed.src}
              className={styles.headerImg}
              alt="LeapLane Kangaroo"
            />
          </div>
        </div>
      </header>
      <section className={`${styles.section} ${styles.areas}`}>
        <div className={styles.container}>
          <h1 className={styles.sectionTitle}>
            {businessFeaturesSection.title}
            <Pontuation>.</Pontuation>
          </h1>
          <p className={styles.sectionDescription}>
            {businessFeaturesSection.subtitle}
          </p>
          <div className={styles.row}>
            {businessFeaturesSection.features.map(feature => (
              <Card
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
              />
            ))}
          </div>
        </div>
      </section>

      <section className={`${styles.section} ${styles.values}`}>
        <div className={styles.container}>
          <div className={styles.left}>
            <img
              className={styles.rectangles}
              src={valuesImg.fixed.src}
              alt=""
            />
          </div>
          <div className={styles.right}>
            <h1 className={styles.sectionTitle}>
              {valuesSection.title}
              <Pontuation>.</Pontuation>
            </h1>
            <ReactMarkdown
              className={styles.sectionDescription}
              source={valuesSection.description}
            />
          </div>
        </div>
      </section>

      <section className={`${styles.section} ${styles.technologies}`}>
        <div className={styles.container}>
          <div className={styles.left}>
            <h1 className={styles.sectionTitle}>
              {technologySection.title}
              <Pontuation>.</Pontuation>
            </h1>
            <p className={styles.sectionDescription}>
              {technologySection.description}
            </p>
            <div className={styles.row}>
              {technologySection.icons.map(icon => (
                <img
                  className={styles.techLogo}
                  key={icon.name}
                  src={icon.icon.childImageSharp.fluid.src}
                  alt={icon.name}
                />
              ))}
            </div>
          </div>
          <div className={styles.right}>
            <TechSection
              title="Frontend"
              techs={technologySection.icons.filter(
                tech => tech.category === "frontend"
              )}
            />
            <TechSection
              title="Backend"
              techs={technologySection.icons.filter(
                tech => tech.category === "backend"
              )}
            />
            <TechSection
              title="DevOps"
              techs={technologySection.icons.filter(
                tech => tech.category === "devops"
              )}
            />
          </div>
        </div>
      </section>

      <section className={`${styles.section} ${styles.ctas}`}>
        <div className={styles.container}>
          <div className={styles.left}>
            <h1 className={styles.sectionTitle}>{portfolioSection.title}</h1>
            <p className={styles.sectionDescription}>
              {portfolioSection.description}
            </p>
            <Link to="/portfolio">
              <Button type="darkTransparent">See projects</Button>
            </Link>
          </div>
          <div className={styles.right}>
            <h1 className={styles.sectionTitle}>{contactSection.title}</h1>
            <p className={styles.sectionDescription}>
              {contactSection.description}
            </p>
            <Link to="/contacts">
              <Button type="transparent">Contact us</Button>
            </Link>
          </div>
        </div>
      </section>

      <section className={`${styles.section} ${styles.customers}`}>
        <div className={styles.container}>
          <h1 className={styles.sectionTitle}>
            {customersSection.title}
            <Pontuation>.</Pontuation>
          </h1>
          <div className={styles.row}>
            {customersSection.customers.map(customer => (
              <img
                className={styles.logo}
                key={customer.name}
                src={customer.icon.childImageSharp.fluid.src}
                alt={customer.name}
              />
            ))}
          </div>
        </div>
      </section>
    </SimpleLayout>
  )
}

export default Home
